<template>
  <img
    id="example"
    src="@/assets/ARTilePreviewImages/2.png"
    class="fake-hide"
  />
  <img
    id="maskImg"
    src="@/assets/ARTilePreviewImages/Mask02.png"
    class="fake-hide"
  />
</template>

<script>
import cv from "@techstark/opencv-js";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "MLComponent",
  data() {
    return {
      model: "",
      fileUpload: "",
    };
  },
  mounted: function () {
    this.$store.commit("setLoading", 1);
    this.allMaskLoaded = 0; // we need to know if the mask is ready and open cv is ready before we can clean the mask
    this.fileUpload = document.getElementById("file-upload");
    this.inputImage = document.getElementById("inputImage");
    this.maskImg = document.getElementById("maskImg");
    this.outputImage = document.getElementById("outputImage");
    this.ctx_outputImage = this.outputImage.getContext("2d");
    this.perspective = document.getElementById("perspective");
    this.mask = document.getElementById("mask");
    this.ctx_mask = this.mask.getContext("2d");

    this.inputImage.width = 683;
    this.inputImage.height = 1024;

    document.getElementById("example").addEventListener("load", () => {
      this.inputImage
        .getContext("2d")
        .drawImage(
          document.getElementById("example"),
          0,
          0,
          this.inputImage.width,
          this.inputImage.height
        );
      console.log("Loaded example");
    });
    this.maskImg.onload = () => {
      this.allMaskLoaded++;
      if (this.allMaskLoaded == 2) this.cleanUpMask();
      this.maskImg.onload = null;
    };
    cv["onRuntimeInitialized"] = () => {
      this.allMaskLoaded++;
      if (this.allMaskLoaded == 2) this.cleanUpMask();
    };
    this.fileUpload.addEventListener(
      "change",
      () => {
        this.uploadPhoto();
      },
      false
    );
    this.$store.commit("setLoading", -1);
  },
  methods: {
    uploadPhoto() {
      const scale = 1024;
      const file = this.fileUpload.files[0];
      if (file) {
        this.$store.commit("setLoading", 1);
        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
          this.inputImage.width =
            img.width >= img.height
              ? scale
              : Math.round((img.width / img.height) * scale);
          this.inputImage.height =
            img.height >= img.width
              ? scale
              : Math.round((img.height / img.width) * scale);
          this.inputImage
            .getContext("2d")
            .drawImage(
              img,
              0,
              0,
              this.inputImage.width,
              this.inputImage.height
            );
          //PREDICT
          const storage = getStorage();
          const ImagesRef = ref(storage, "upload/" + uuidv4() + ".jpg");
          this.inputImage.toBlob((blob) => {
            uploadBytes(ImagesRef, blob).then(() => {
              getDownloadURL(ref(storage, ImagesRef)).then((url) => {
                let apiURL =
                  "https://mlfloor01.drinksomecoffee.com/?image_path=" +
                  window.btoa(url);
                fetch(apiURL)
                  .then((response) => response.blob())
                  .then((blob) => {
                    var mask = URL.createObjectURL(blob);
                    this.maskImg.setAttribute("src", mask);
                    this.maskImg.onload = () => {
                      this.cleanUpMask();
                      this.$store.commit("redraw", 1);
                    };
                    this.$store.commit("setLoading", -1);
                  })
                  .catch((error) => {
                    console.log(error);
                    this.$store.commit("setLoading", -1);
                    alert("No floor detected");
                  }); //end fetch
              }); //endgetdownloadurl
            });
          });
        };
      }
    },
    cleanUpMask() {
      this.$store.commit("setLoading", 1);
      this.mask.width = this.inputImage.width;
      this.mask.height = this.inputImage.height;
      this.ctx_mask.drawImage(this.maskImg, 0, 0);
      var myImage = this.ctx_mask.getImageData(
        0,
        0,
        this.mask.width,
        this.mask.height
      );
      var picLength = this.mask.width * this.mask.height;
      // Loop through data.
      let foundTopPixel = false;
      for (var i = 0; i < picLength * 4; i += 4) {
        const index = i / 4;
        if (
          myImage.data[i] == 255 &&
          myImage.data[i + 1] == 255 &&
          myImage.data[i + 2] == 255
        ) {
          myImage.data[i] = 0;
          myImage.data[i + 1] = 0;
          myImage.data[i + 2] = 0;
          myImage.data[i + 3] = 0;
        } else {
          if (!foundTopPixel) {
            this.$store.commit(
              "setTopPixelOfMask",
              Math.floor(index / this.mask.width)
            );
            foundTopPixel = true;
          }
          myImage.data[i] = 255;
          myImage.data[i + 1] = 255;
          myImage.data[i + 2] = 255;
          myImage.data[i + 3] = 255;
          //points.push([x,y]);
        }
      }
      this.ctx_mask.globalCompositeOperation = "copy";
      this.ctx_mask.putImageData(myImage, 0, 0);
      //blur
      let src = cv.imread("mask");
      let ksize = new cv.Size(7, 7);
      cv.GaussianBlur(src, src, ksize, 0, 0, cv.BORDER_DEFAULT);
      cv.imshow("mask", src);
      src.delete();
      this.$store.commit("setLoading", -1);
    },
  },
};
</script>
